'use client'

import { type MainNavigation } from 'cms-types'
import { LazyMotion, m, useScroll, useTransform } from 'framer-motion'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useMemo, useRef } from 'react'
import { addAlert, Navigation } from 'ui'

import { logger } from '@/helpers/logger'
import { useSSR } from '@/hooks/useSSR'
import { useCart } from '@/store/cartStore'
import { useCustomer } from '@/store/customerStore'

import { pageToUrl, resolveRelation } from '../helpers'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

export default function MainNavigation(props: MainNavigation) {
  const cart = useCart()
  const { isClient } = useSSR()
  const pathname = usePathname()
  const router = useRouter()
  const searchParams = useSearchParams()
  const headerRef = useRef<HTMLHeadElement>(null)
  const scrollOffset = useRef(0)
  const customer = useCustomer()

  const shoppingBasketItemsCount = useMemo(() => {
    if (!cart.data) return

    return cart.data.lines.reduce((accum, item) => accum + item.quantity, 0)
  }, [cart.data])

  const mappedMenuItems = useMemo(() => {
    let activeLinkIndex = null
    let maximumMatches = 0

    const splittedCurrentPathname = pathname.split('/').slice(1)

    const mappedItem =
      props.menu?.map(({ title, page, id }, linkIndex) => {
        const linkUrl = pageToUrl(page) ?? ''

        const splittedLink = linkUrl.split('/').slice(1)

        let pathMatches = 0

        splittedLink.forEach((path, pathIndex) => {
          if (path === splittedCurrentPathname[pathIndex]) {
            pathMatches++
          }
        })

        if (
          pathMatches === 0 &&
          splittedLink[0] === 'hautwissen' &&
          splittedCurrentPathname[0] === 'post'
        ) {
          activeLinkIndex = linkIndex
          maximumMatches = 1
        }

        if (pathMatches > maximumMatches) {
          activeLinkIndex = linkIndex
          maximumMatches = pathMatches
        }

        return {
          title,
          url: linkUrl,
          id,
          isActive: false,
        }
      }) ?? []

    if (activeLinkIndex != null) {
      mappedItem[activeLinkIndex].isActive = true
    }

    return mappedItem
  }, [pathname, props.menu])

  const onSearchSubmit = useCallback(
    (query: string) => {
      if (!isClient) return

      const currentQuery = searchParams.get('a')

      if (currentQuery === query) return

      router.push(`/suche/${query}`)
    },
    [isClient, router, searchParams],
  )

  const logo = resolveRelation(props.logo?.image)

  const goToLogout = async () => {
    try {
      await customer.logout()
      if (pathname === '/') {
        router.refresh()
      } else {
        router.push('/')
      }
    } catch (error) {
      logger.error('MainNavigation > goToLogout', error)
      addAlert.error('Logout fehlgeschlagen')
    }
  }

  const goToLogin = () => {
    router.push('/account/login')
  }

  const goToProfile = () => {
    router.push('/account')
  }

  const { scrollY } = useScroll()

  const transform = useTransform(scrollY, scrollPosition => {
    if (!headerRef.current || scrollPosition < headerRef.current.clientHeight / 2)
      return 'translateY(0)'

    const translate = scrollPosition > scrollOffset.current ? 'translateY(-100%)' : 'translateY(0)'
    scrollOffset.current = scrollPosition

    return translate
  })

  return (
    <LazyMotion features={motionFeatures} strict>
      <m.header
        ref={headerRef}
        className='sticky top-0 z-50 transition-transform duration-300 ease-in-out'
        style={{ transform }}
      >
        <Navigation
          onShoppingBasketIconClick={() => cart.setIsOpen(true)}
          shoppingBasketItemsCount={shoppingBasketItemsCount}
          onSearchSubmit={onSearchSubmit}
          logo={logo}
          logoUrl={pageToUrl(props.logo?.page) ?? ''}
          searchEnabled={props.search?.enabled}
          searchFieldPlaceholder={props.search?.placeholder}
          searchButtonLabel={props.search.searchButtonLabel}
          closeSearchButtonLabel={props.search.closeButtonLabel}
          searchSuggestion={
            props.search
              ? {
                  title: props.search.suggestionList.suggestionListTitle,
                  links:
                    props.search.suggestionList.links?.map(({ title, id }) => ({
                      title,
                      id,
                    })) ?? [],
                }
              : undefined
          }
          shoppingBasketEnabled={props.shoppingBasket?.enabled}
          loginEnabled={props.login.enabled}
          loginTypography={props.login.typography}
          isLogin={customer.token != null}
          menuItems={mappedMenuItems}
          burgerMenuAdditionalListItems={
            props.burgerMenuAdditionalList?.map(({ title, page, id }) => ({
              title,
              url: pageToUrl(page) ?? '',
              id,
            })) ?? []
          }
          goToLogout={goToLogout}
          goToLogin={goToLogin}
          goToProfile={goToProfile}
        />
      </m.header>
    </LazyMotion>
  )
}
