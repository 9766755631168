'use client'

import { GlobalTypography } from 'cms-types'
import { useEffect } from 'react'
import { AlertContainer } from 'ui'

import { useMagicLinksHandling } from '@/hooks/useMagicLinksHandling'
import { useCart } from '@/store/cartStore'
import { useCustomer } from '@/store/customerStore'
import { useGlobalTypography } from '@/store/globalTypographyStore'
import { usePreviousPath } from '@/store/previousPathStore'

type SetupClientComponentProps = {
  globalTypography: GlobalTypography
}

/**
 * This component will setup client side functionality
 */
const SetupClientComponent = ({ globalTypography }: SetupClientComponentProps) => {
  const cart = useCart()

  useEffect(() => {
    useGlobalTypography.setState(globalTypography)
    usePreviousPath.persist.rehydrate()
    useCart.persist.rehydrate()
  }, [globalTypography])

  useEffect(() => {
    if (cart.isLoaded) {
      useCustomer.persist.rehydrate()
    }
  }, [cart.isLoaded])

  useMagicLinksHandling()

  return <AlertContainer position={cart.isOpen ? 'top-left' : 'top-right'} />
}

export default SetupClientComponent
