import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { getShopifyGID } from 'shopify-types/helpers'
import { addAlert } from 'ui'

import { logger } from '@/helpers/logger'
import { useCart } from '@/store/cartStore'
import { useGlobalTypography } from '@/store/globalTypographyStore'

export const useMagicLinksHandling = () => {
  const cart = useCart()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const router = useRouter()
  const { magicLinksToastsCustomization } = useGlobalTypography()

  const productsToAdd = searchParams.get('rs')?.split(',') ?? []
  const giftCards = searchParams.get('giftcard')?.split(',') ?? []
  const discounts = searchParams.get('discount')?.split(',') ?? []

  const isMagicLink = productsToAdd.length > 0 || giftCards.length > 0 || discounts.length > 0

  const addProductsToAdd = async () => {
    if (productsToAdd.length === 0) return

    const parsedProducts = productsToAdd
      .filter(pair => pair.includes(':'))
      .map(pair => {
        const [variantId, quantity] = pair.split(':')
        return {
          variantId: getShopifyGID(variantId, 'ProductVariant'),
          quantity: +quantity,
        }
      })

    if (parsedProducts.length === 0) return

    try {
      await cart.addToBasket(parsedProducts)
      cart.setIsOpen(true)
    } catch (error) {
      logger.error('useMagicLinksHandling > addProductsToAdd', error)
      addAlert.error(magicLinksToastsCustomization.products.error)
    }
  }

  const applyDiscounts = async () => {
    if (discounts.length === 0) return

    try {
      await cart.applyDiscount(discounts)
      cart.setIsOpen(true)
    } catch (error) {
      logger.error('useMagicLinksHandling > applyDiscount', error)
      addAlert.error(magicLinksToastsCustomization.discounts.error)
    }
  }

  const applyGiftCards = async () => {
    if (giftCards.length === 0) return

    try {
      // Handle already applied gift cards
      const usedCards = giftCards.filter(giftCard => cart.giftCards.includes(giftCard))
      if (usedCards.length > 0) {
        usedCards.forEach(giftCard => {
          addAlert.warning(
            `${giftCard}: ${magicLinksToastsCustomization.giftCards.cardAlreadyAppliedError}`,
          )
        })
      }

      // Handle new gift cards
      const newCards = giftCards.filter(giftCard => !cart.giftCards.includes(giftCard))
      if (newCards.length > 0) {
        await cart.applyGiftCard(newCards)
      }

      // Handle gift cards that are new but were not applied
      const newCart = useCart.getState()
      const unappliedCards = newCards.filter(giftCard => !newCart.giftCards.includes(giftCard))

      if (unappliedCards.length > 0) {
        unappliedCards.forEach(giftCard => {
          addAlert.error(`${giftCard}: ${magicLinksToastsCustomization.giftCards.invalidCardError}`)
        })
      }

      cart.setIsOpen(true)
    } catch (error) {
      logger.error('useMagicLinksHandling > applyGiftCards', error)
      addAlert.error(magicLinksToastsCustomization.giftCards.invalidCardError)
    }
  }

  useEffect(() => {
    if (cart.isLoaded && isMagicLink) {
      router.replace(pathname, { scroll: false })

      const handleAllMagicLinks = async () => {
        try {
          await addProductsToAdd()
          await applyDiscounts()
          await applyGiftCards()
        } catch (error) {
          logger.error('useMagicLinksHandling > useEffect', error)
          addAlert.error('Etwas ist schief gelaufen')
        }
      }
      handleAllMagicLinks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.isLoaded, isMagicLink])
}
