'use client'

import { type BottomNavigation } from 'cms-types'
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation'
import { Footer } from 'ui'

import { NextRoute } from '@/common/types'

import { pageToUrl, resolveRelation } from '../helpers'

export default function BottomNavigation(props: BottomNavigation) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const locale = useParams<NextRoute['params']>().locale
  const isProductPage = pathname.includes('/produkte/')
  const router = useRouter()

  const onChangeLanguage = (language: string) => {
    const targetDomain =
      language === 'en'
        ? process.env.NEXT_PUBLIC_WEBSITE_PUBLIC_URL_ENGLISH
        : process.env.NEXT_PUBLIC_WEBSITE_PUBLIC_URL_GERMAN

    router.push(
      `${targetDomain}${pathname}${
        Array.from(searchParams.values()).length > 0 ? `?${searchParams}` : ''
      }`,
    )
  }
  return (
    <footer>
      <Footer
        registrationBanner={props.registrationBanner}
        isRegistrationBannerEnabled={Boolean(props.isRegistrationBannerEnabled) && !isProductPage}
        columns={
          props.columns?.map(({ title, pages, id }) => ({
            columnTitle: title,
            pages:
              pages?.map(({ title, page, id }) => ({
                title,
                url: pageToUrl(page) ?? '',
                id,
              })) ?? [],
            id,
          })) ?? []
        }
        socials={
          props.socials?.map(item => ({
            ...item,
            icon: resolveRelation(item.icon),
          })) ?? []
        }
        logo={resolveRelation(props.logo.image)}
        logoUrl={pageToUrl(props.logo?.page) ?? ''}
        footerLegalInformation={
          props.footerLegalInformation?.map(({ title, page, id }) => ({
            title,
            url: pageToUrl(page) ?? '',
            id,
          })) ?? []
        }
        languageTypography={{
          switchTitle: props.languageSwitch.switchTitle,
          modalTitle: props.languageSwitch.modalTitle,
          modalButtonText: props.languageSwitch.modalConfirmText,
        }}
        locale={locale}
        onChangeLanguage={onChangeLanguage}
      />
    </footer>
  )
}
