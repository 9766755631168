'use client'

import { ShoppingBasket } from 'cms-types'
import { useRouter } from 'next/navigation'
import { ComponentProps, useCallback, useState } from 'react'
import { addAlert, ShoppingBasket as UIShoppingBasket } from 'ui'

import { getLocaleFromHostname } from '@/helpers/getLocaleFromHostname'
import { logger } from '@/helpers/logger'
import { MAX_PER_ITEM_IN_CART, useCart } from '@/store/cartStore'

type ShoppingBasketClientWrapperProps = Omit<ShoppingBasket, 'upSell'> & {
  upSell: ComponentProps<typeof UIShoppingBasket>['upSell']
}

function ShoppingBasketClientWrapper({
  upSell,
  title,
  checkoutButtonText,
  totalPriceText,
  deleteItemText,
  accessability,
}: ShoppingBasketClientWrapperProps) {
  const cart = useCart()

  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)

  const onClose = useCallback(() => {
    cart.setIsOpen(false)
  }, [cart])

  const onUpdateItemQuantity = useCallback(
    async (data: { quantity: number; productId: string; variantId: string }[]) => {
      setIsLoading(true)

      try {
        await cart.updateQuantity(
          data.map(({ variantId, quantity }) => ({ variantId, quantity, mode: 'set' })),
        )
      } catch (e) {
        logger.error('ShoppingBasket > onUpdateItemQuantity', e)

        addAlert.error('Fehler beim Aktualisieren des Produkts')
      } finally {
        setIsLoading(false)
      }
    },
    [cart],
  )

  const onItemDelete = useCallback(
    async ({ variantId }: { variantId?: string }) => {
      setIsLoading(true)

      try {
        if (variantId == null) return
        await cart.removeFromBasket([variantId])
      } catch (e) {
        logger.error('ShoppingBasket > onItemDelete', e)

        addAlert.error('Fehler beim Entfernen des Produkts')
      } finally {
        setIsLoading(false)
      }
    },
    [cart],
  )

  const onAddUpSellProduct = useCallback(async () => {
    if (upSell?.isEnabled !== true) return

    const variantId = upSell.variant.id
    setIsLoading(true)

    try {
      await cart.addToBasket([{ variantId }])
    } catch (e) {
      logger.error('ShoppingBasket > onAddUpSellProduct', e)

      addAlert.error('Fehler beim Hinzufügen eines Produkts')
    } finally {
      setIsLoading(false)
    }
  }, [cart, upSell])

  const onCheckoutSubmit = useCallback(() => {
    if (!cart.data) return

    const url = new URL(cart.data.checkoutUrl)
    url.searchParams.set('t', Date.now().toString())
    url.searchParams.set('locale', getLocaleFromHostname(window.location.hostname))

    router.push(url.toString())
  }, [cart, router])

  return (
    <UIShoppingBasket
      isOpen={cart.isOpen}
      maxPerItemInCart={MAX_PER_ITEM_IN_CART}
      title={title}
      isLoading={isLoading}
      checkoutButtonText={checkoutButtonText}
      totalPriceText={totalPriceText}
      deleteItemText={deleteItemText}
      onClose={onClose}
      cart={cart.data}
      onItemQuantityUpdate={onUpdateItemQuantity}
      onItemDelete={onItemDelete}
      onCheckoutSubmit={onCheckoutSubmit}
      {...(upSell?.isEnabled === true ? { upSell: upSell, onAddUpSellProduct } : upSell)}
      accessability={accessability}
    />
  )
}

export default ShoppingBasketClientWrapper
